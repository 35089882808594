.detailsI {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
}

.input_Data {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  background-color: rgb(252, 252, 252);
  /* border: 1px solid var (--black2); */
}

.detailsII {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.detailsIII {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.detailsI .dataIbu,
.detailsII .dataIbu {
  position: relative;
  display: grid;
  min-height: 50px;
  background: var (--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.detailsI .dataIbu {
  position: relative;
  display: grid;
  min-height: 50px;
  background: var (--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.detailsII .dataIbu h2 {
  font-weight: 600;
  color: blue;
}

.btn {
  position: relative;
  padding: 5px 10px;
  background: #287bff;
  text-decoration: none;
  color: white;
  border-radius: 6px;
}
.btnD {
  position: relative;
  padding: 5px 10px;
  background: #f81717;
  text-decoration: none;
  color: white;
  border-radius: 6px;
}

.detailsI table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.detailsI table thead td {
  font-weight: 600;
}

.detailsII .dataIbu table tr {
  color: var(--black1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.detailsII .dataIbu table tr:last-child {
  border-bottom: none;
}
.details .dataIbu table tr:last-child {
  border-bottom: none;
}

.details .dataIbu table tbody tr:hover {
  background: var(--blue);
  color: var (--white);
}

.details .dataIbu table tr td {
  padding: 10px;
}

.details .dataIbu table tr td:last-child {
  text-align: end;
}

.details .dataIbu table tr td:nth-child(2) {
  text-align: end;
}
.details .dataIbu table tr td:nth-child(3) {
  text-align: center;
}

.delivered {
  padding: 2px 4px;
  background: #8de02c;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.return {
  padding: 2px 4px;
  background: #f02906;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.pending {
  padding: 2px 4px;
  background: #f3db04;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.recentCustomers {
  position: relative;
  display: grid;
  min-height: 500px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.recentCustomers .imgBx {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.profile {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.recentCustomers .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dataIbu table tr:hover {
  background: var (--blue);
  color: var(--white);
}
.dataIbu table tr td {
  padding: 12px 10px;
}
.dataIbu table tr td h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2em;
}

.dataIbu table tr td h4 span {
  font-size: 14px;
  color: var (--black2);
}

.dataIbu table tr:hover {
  background: var(--blue);
  color: var (--white);
}

.dataIbu table tr:hover span {
  color: var (--white);
}
