.leaflet-container {
  /* background-color: white; */
  /* background-color: rgb(178, 210, 222); */
  background-color: rgb(248, 249, 250);
}

.bingkaiPeta {
  position: relative;

  min-height: 500px;
  background: var (--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}
.legend {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: -70px;
  margin: 10px;
  background-color: var (--white);
  width: 100%;
  height: 20%;
  border-radius: 5px;
}
.legendBox {
  position: absolute;
  display: flex;
  justify-content: space-between;
}
.lg1 {
  top: 10px;
  display: flex;
  justify-content: center;
  color: azure;
  background-color: red;
  width: 20%;
  height: 20%;

  margin-top: 5px;
}
.lg2 {
  display: flex;
  justify-content: center;
  background-color: orange;
  width: 20%;
  height: 20%;
  color: azure;
  margin-top: 5px;
}
.lg3 {
  display: flex;
  justify-content: center;
  background-color: green;
  width: 20%;
  height: 20%;
  color: azure;
  margin-top: 5px;
}
.lg4 {
  display: flex;
  justify-content: center;
  background-color: blue;
  width: 20%;
  height: 20%;
  color: azure;
  margin-top: 5px;
}
.leaflet-popup-content-wrapper {
  border-radius: 0;
}

h2 {
  left: 50%;
}
.select {
  left: 0rem;
  bottom: 0.5rem;
}
