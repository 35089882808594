@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', 'Helvetica Neue';
}

:root {
  --blue: #287bff;
  --white: #fff;
  --grey: #f5f5f5;
  --black1: #222;
  --black2: #999;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: relative;
  width: 100%;
}

.navigation {
  position: fixed;
  width: 250px;
  height: 100%;

  border-left: 10 px solid var(--blue);
  transition: hidden;
  overflow: hidden;
}

.navigation.active {
  width: 70px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 0;
  width: 100%;
}

.navigation ul .navi li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.navigation ul .navi li:hover {
  background: var(--white);
}
.navigation ul .navi li:nth-child(1) {
  margin-bottom: 10px;
  pointer-events: none;
}

.navigation ul .navi li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--white);
}
a {
  text-decoration: none;
}

.navigation ul .navi li:hover a,
.navigation ul .navi li.hovered a {
  color: var(--blue);
}

.navigation ul .navi li .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 40px;
  line-height: 70px;
  text-align: center;
}

#imgKu {
  height: 80px;
  width: 80px;
  margin-top: 0.5rem;
  margin-left: 30%;
  margin-bottom: 0.5rem;
}
#liKu {
  margin-bottom: 2rem;
}

.navigation ul .navi li .icon i {
  font-size: 1.75em;
}
.navigation ul .navi li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 40px;
  line-height: 60px;
  text-align: center;
  white-space: nowrap;
}
#titleku {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  font-size: 1.7em;
}

/*  curve oustside */

.navigation ul .navi li:hover a::before,
.navigation ul .navi li.hovered a::before {
  content: '';
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--white);
  pointer-events: none;
}
.navigation ul .navi li:hover a::after,
.navigation ul .navi li.hovered a::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
}

/* main */

.main {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  min-height: 100vh;
  background: var(--white);
  transition: 0.5s;
}

.main.active {
  width: calc(100% - 70px);
  left: 70px;
}

.topbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10 px;
}

.toggle {
  position: relative;
  top: 0;
  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  cursor: pointer;
}

.search {
  position: relative;
  width: 400px;
  margin: 0 10px;
}

.search label {
  position: relative;
  width: 100%;
}

.search label input {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid var (--black2);
}

.search label i {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 1.2em;
}

.user {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  right: 30px;
}

#user {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.cardBox {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.cardBox .card {
  position: relative;
  background: var (--white);
  padding: 30px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.cardBox .card .number {
  position: relative;
  font-weight: 500;
  font-size: 2.5em;
  color: var (--blue);
}

.cardBox .card .cardName {
  color: var (--black2);
  font-size: 1.1em;
}
.cardBox .card .iconBx {
  font-size: 3.5em;
  color: var (--black2);
}

.cardBox .card:hover {
  background: #287bff;
}

.cardBox .card:hover .number,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx {
  color: var (--white);
}

.details {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
}

.details .recentOrders {
  position: relative;
  display: grid;
  min-height: 500px;
  background: var (--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cardHeader h2 {
  font-weight: 600;
  color: var(--blue);
}

.btn {
  position: relative;
  padding: 5px 10px;
  background: #287bff;
  text-decoration: none;
  color: white;
  border-radius: 6px;
}

.details table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.details table thead td {
  font-weight: 600;
}

.details .recentOrders table tr {
  color: var(--black1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.details .recentOrders table tr:last-child {
  border-bottom: none;
}
.details .recentOrders table tr:last-child {
  border-bottom: none;
}

.details .recentOrders table tbody tr:hover {
  background: var(--blue);
  color: var (--white);
}

.details .recentOrders table tr td {
  padding: 10px;
}

.details .recentOrders table tr td:last-child {
  text-align: end;
}

.details .recentOrders table tr td:nth-child(2) {
  text-align: end;
}
.details .recentOrders table tr td:nth-child(3) {
  text-align: center;
}

.delivered {
  padding: 2px 4px;
  background: #8de02c;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.return {
  padding: 2px 4px;
  background: #f02906;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.pending {
  padding: 2px 4px;
  background: #f3db04;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.recentCustomers {
  position: relative;
  display: grid;
  min-height: 500px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.recentCustomers .imgBx {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.recentCustomers .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recentCustomers table tr:hover {
  background: var (--blue);
  color: var(--white);
}
.recentCustomers table tr td {
  padding: 12px 10px;
}
.recentCustomers table tr td h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2em;
}

.recentCustomers table tr td h4 span {
  font-size: 14px;
  color: var (--black2);
}

.recentCustomers table tr:hover {
  background: var(--blue);
  color: var (--white);
}

.recentCustomers table tr:hover span {
  color: var (--white);
}

/* now make it responsive */
@media (max-width: 991px) {
  .navigation {
    left: -300px;
  }
  .navigation.active {
    width: 300px;
    left: 0;
  }
  .main {
    width: 100%;
    left: 0;
  }
  .main.active {
    left: 300px;
  }
  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 721px) {
  .details {
    grid-template-columns: repeat(1, 1fr);
  }
  .recentOrders {
    overflow-x: auto;
  }
  .status.inprogress {
    white-space: nowrap;
  }
}

@media (max-width: 533px) {
  .cardBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .cardHeader h2 {
    font-size: 20px;
  }
  .user {
    min-width: 40px;
  }
  .navigation {
    width: 100%;
    left: 100%;
    z-index: 1000;
  }
  .navigation.active {
    width: 100%;
    left: 0;
  }
  .toggle {
    z-index: 1001;
  }
  .main.active .toggle {
    position: fixed;
    right: 0;
    left: initial;
    color: #fff;
  }
}
