.row {
  margin: 10px;
}

form {
  box-shadow: none;
}
.imgProfile {
  border-radius: 50%;
  border: 2px solid rgb(29, 9, 9);
}
.imgBx {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.imgPrKomen {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgb(234, 112, 112);
}
.profileKomen {
  width: 40px;
  height: 40px;
}
.imgPrInfo {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgb(234, 112, 112);
  margin-right: 5px;
}
.profileInfo {
  width: 35px;
  height: 35px;
}
.imgPr {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgb(234, 112, 112);
}
.profileKu {
  width: 50px;
  height: 50px;
}
.imgPrTop {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgb(241, 237, 237);
}
.profileKuTop {
  width: 40px;
  height: 40px;
}
.likes {
  width: 100%;
  border-top: 5px;
}
.like {
  color: rgb(46, 82, 239);
  text-align: center;
  width: 33%;
  cursor: pointer;
  font-weight: bold;
}

.like:hover {
  background-color: rgb(237, 244, 244);
  border-radius: 10%;
}
.komentars {
  margin-top: 5px;
  margin-left: 60px;
  background-color: rgb(237, 244, 244);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.namaKomen {
  font-weight: 1000;
  font-size: 14px;
  color: rgb(57, 57, 238);
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.judulInfo {
  color: rgb(57, 57, 238);
  font-weight: 400;
}
.heading {
  font-weight: 1000;
  font-size: 18px;
  color: rgb(57, 57, 238);
  margin-bottom: 10px;
  margin-top: 0px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.tanggalKomen {
  font-weight: 1000;
  font-size: 12px;
  color: rgb(39, 39, 87);
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.buttonBox {
  margin-top: 20px;
}

.tombol {
  margin-top: 10px;
  width: 100%;
}

.floatKiri {
  position: fixed;
  left: 300px;
  top: 120px;

  font-size: large;
  text-align: center;
  text-justify: center;
  cursor: pointer;

  width: 60px;
  height: 60px;
}
.floatKiri2 {
  position: fixed;
  left: 300px;
  top: 170px;

  font-size: large;
  text-align: center;
  text-justify: center;
  cursor: pointer;

  width: 60px;
  height: 60px;
}
.addBtn {
  width: 50px;
  height: 50px;
}

.addBtn :hover {
  width: 60px;
  height: 60px;
}

.statusku {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .floatKiri {
    position: fixed;
    left: 40px;
    top: 120px;

    font-size: large;
    text-align: center;
    text-justify: center;
    cursor: pointer;

    width: 60px;
    height: 60px;
  }
  .floatKiri2 {
    position: fixed;
    left: 40px;
    top: 170px;

    font-size: large;
    text-align: center;
    text-justify: center;
    cursor: pointer;

    width: 60px;
    height: 60px;
  }
}
