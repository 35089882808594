form {
  width: 97%;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  border: 0.1rem solid rgba(43, 42, 42, 0.2);
  background: #fff;
  padding: 1.5rem;
  margin: 0 auto;
  position: relative;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-right: 2em;
}

.puji {
  position: fixed;
  right: 60px;
  top: 60px;
  background-color: #27ae60;
  font-size: large;
  text-align: center;
  text-justify: center;

  width: 30px;
  height: 30px;
}

.rojati {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: azure;
}

.kolom {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
}

.kolom .bagiKolom {
  position: relative;
  display: grid;
  min-height: 500px;
  background: var (--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}
